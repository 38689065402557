import React from 'react';

const ErrorModal = ({ message, onClose }) => {
    if (!message) return null; // Do not render if there's no error message

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-gray-800 text-white rounded-lg p-6 w-96 shadow-lg">
                <h2 className="text-xl font-bold text-red-500 mb-4">Error</h2>
                <p className="text-sm text-gray-300 mb-6">{message}</p>
                <button
                    onClick={onClose}
                    className="w-full bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default ErrorModal;
