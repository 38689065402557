import React from 'react';

const Dashboard = ({ userData }) => {
    return (
        <div className="text-center">
            <h1>Welcome, {userData?.display_name}!</h1>
            <img
                src={userData?.images?.[0]?.url || '/default-avatar.png'}
                alt="User Avatar"
                className="avatar w-40 h-40 mx-auto mt-6"
            />
            <p className="mt-4">Email: {userData?.email}</p>
            <p>Followers: {userData?.followers?.total}</p>
        </div>
    );
};

export default Dashboard;
