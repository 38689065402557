import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const TrackList = ({ tracks, numberOfTracks, handleRemoveTrack }) => (
    <ul className="mt-4">
        <AnimatePresence initial={false}>
            {tracks
                .slice(0, numberOfTracks)
                .reverse()
                .map((track) => (
                    <motion.li
                        key={track.id}
                        className="flex items-center space-x-4 mb-4 p-3 rounded-lg transition hover:bg-gray-800"
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 20 }}
                        transition={{ duration: 0.2 }}
                    >
                        <img
                            src={track.album.images[0]?.url || '/default-track.png'}
                            alt={track.name}
                            className="w-12 h-12 rounded"
                        />
                        <div className="flex-1">
                            <p className="text-lg font-semibold">{track.name}</p>
                            <p className="text-sm text-gray-400">
                                {track.artists.map((a) => a.name).join(', ')}
                            </p>
                        </div>
                        <button
                            onClick={() => handleRemoveTrack(track.id)}
                            className="p-2 bg-red-500 hover:bg-red-600 text-white rounded"
                        >
                            ✕
                        </button>
                    </motion.li>
                ))}
        </AnimatePresence>
    </ul>
);

export default TrackList;
