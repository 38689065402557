import React, { useEffect } from 'react';

const PlaylistNameInput = ({ playlistName, setPlaylistName, numberOfTracks, isCustomName, setIsCustomName }) => {
    useEffect(() => {
        if (!isCustomName) {
            setPlaylistName(`Top ${numberOfTracks} Songs`);
        }
    }, [numberOfTracks, isCustomName, setPlaylistName]);

    const handleNameChange = (e) => {
        setPlaylistName(e.target.value);
        setIsCustomName(e.target.value !== `Top ${numberOfTracks} Songs`);
    };

    return (
        <div className="mb-4">
            <label className="block mb-2 text-lg font-semibold text-white">
                Playlist Name (editable):
            </label>
            <input
                type="text"
                className="w-full p-3 rounded bg-gray-800 text-white placeholder-gray-400"
                placeholder={`Top ${numberOfTracks} Songs`}
                value={playlistName}
                onChange={handleNameChange}
            />
        </div>
    );
};

export default PlaylistNameInput;
