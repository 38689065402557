import React from 'react';
import { motion } from 'framer-motion';
import ArtistChart from './Visualizations/ArtistChart'; // Import the chart component

const TopArtists = ({ artists }) => {
    return (
        <motion.div
            className="space-y-8"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
        >
            {/* Title */}
            <h2 className="text-4xl font-bold text-center">Your Top Artists</h2>

            {/* Artist Grid */}
            <div className="dashboard-grid">
                {artists.map((artist) => (
                    <div key={artist.id} className="card">
                        <img
                            src={artist.images?.[0]?.url || '/default-artist.png'}
                            alt={artist.name}
                            className="rounded-lg w-full h-40 object-cover mb-4"
                        />
                        <h3>{artist.name}</h3>
                        <p>Popularity: {artist.popularity}</p>
                        <p>
                            Genres: {artist.genres?.length > 0 ? artist.genres.join(', ') : 'No genres available'}
                        </p>
                    </div>
                ))}
            </div>

            {/* Artist Chart */}
            {artists.length > 0 && (
                <div className="space-y-8">
                    <h2 className="text-4xl font-bold text-center">Top Artists Popularity Chart</h2>
                    <ArtistChart artists={artists} />
                </div>
            )}
        </motion.div>
    );
};

export default TopArtists;
