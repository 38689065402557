import React from 'react';

const TrackSlider = ({ numberOfTracks, maxTracks, setNumberOfTracks }) => (
    <div className="mb-6">
        <label className="block mb-2 text-lg font-semibold">Select Number of Songs</label>
        <input
            type="range"
            min="1"
            max={maxTracks}
            value={numberOfTracks}
            onChange={(e) => setNumberOfTracks(Number(e.target.value))}
            className="w-full"
        />
        <p className="text-center mt-2">Adding Top {numberOfTracks} Songs</p>
    </div>
);

export default TrackSlider;
