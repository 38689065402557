export const SPOTIFY_AUTH_ENDPOINT = 'https://accounts.spotify.com/authorize';
export const CLIENT_ID = 'd19153db17d043039af564f8d24218cf'; // Replace with your Spotify Client ID
export const REDIRECT_URI = 'https://spotify.gavingizzie.com/callback'; // Replace with your redirect URI
export const SCOPES = [
    'user-read-email',
    'user-top-read',
    'user-read-recently-played',
    'user-library-read',
    'playlist-read-private',
    'playlist-modify-private',
    'playlist-modify-public',
];
