import React, { useEffect, useState } from 'react';
import Dashboard from '../components/dashboard/Dashboard';
import TopArtists from '../components/dashboard/TopArtists';
import TopTracks from '../components/dashboard/TopTracks';
import { CreatePlaylist } from '../components/dashboard/CreatePlaylist';
import ListeningTrends from '../components/dashboard/Visualizations/ListeningTrends';
import GenrePieChart from '../components/dashboard/Visualizations/GenrePieChart';
import { fetchSpotifyData } from '../utils/SpotifyHelper'; // Import helper

const DashboardPage = () => {
    const [spotifyData, setSpotifyData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('spotifyToken');
        if (token) {
            fetchSpotifyData(token)
                .then((data) => {
                    console.log('Fetched Spotify Data:', data); // Debug fetched data
                    setSpotifyData(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error loading Spotify data:', error);
                    setLoading(false);
                });
        }
    }, []);

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
                <h1 className="text-2xl font-semibold">Loading Spotify Data...</h1>
            </div>
        );
    }

    return (
        <div className="bg-gray-900 text-white min-h-screen p-8 space-y-16">
            {spotifyData?.userProfile && <Dashboard userData={spotifyData.userProfile} />}
            <ListeningTrends />
            <GenrePieChart />
            <TopArtists artists={spotifyData?.topArtists} /> {/* Pass topArtists */}
            <TopTracks tracks={spotifyData?.topTracks} /> {/* Pass topTracks */}
            <CreatePlaylist tracks={spotifyData?.topTracks} />
        </div>
    );
};

export default DashboardPage;
