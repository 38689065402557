import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Register the required components with Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ArtistChart = ({ artists }) => {
    const chartData = {
        labels: artists.map((artist) => artist.name), // X-axis labels
        datasets: [
            {
                label: 'Popularity',
                data: artists.map((artist) => artist.popularity), // Y-axis data
                backgroundColor: [
                    '#1db954', '#ff5733', '#c70039', '#900c3f', '#581845', '#8e44ad', '#3498db', '#27ae60', '#f1c40f', '#e74c3c',
                ],
                borderWidth: 1,
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: '#ffffff', // White labels for dark mode
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#ffffff', // X-axis label color
                },
            },
            y: {
                ticks: {
                    color: '#ffffff', // Y-axis label color
                },
            },
        },
    };

    return (
        <div className="w-full h-96">
            <Bar data={chartData} options={chartOptions} />
        </div>
    );
};

export default ArtistChart;
