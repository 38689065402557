import React from 'react';

const TimeRangeSelector = ({ timeRange, setTimeRange }) => (
    <div className="mb-6">
        <label className="block mb-2 text-lg font-semibold">Select Time Range:</label>
        <select
            value={timeRange}
            onChange={(e) => setTimeRange(e.target.value)}
            className="p-2 bg-gray-800 text-white rounded w-full"
        >
            <option value="short_term">Last 4 Weeks</option>
            <option value="medium_term">Last 6 Months</option>
            <option value="long_term">All Time</option>
        </select>
    </div>
);

export default TimeRangeSelector;
