import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TrackChart = ({ tracks }) => {
    const chartData = {
        labels: tracks.map((track) => track.name.length > 15 ? `${track.name.slice(0, 15)}...` : track.name), // X-axis labels (truncate long names)
        datasets: [
            {
                label: 'Popularity',
                data: tracks.map((track) => track.popularity), // Y-axis data
                backgroundColor: [
                    '#1db954', '#ff5733', '#c70039', '#900c3f', '#581845', '#8e44ad', '#3498db', '#27ae60', '#f1c40f', '#e74c3c',
                ],
                borderWidth: 1,
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: '#ffffff', // White labels for dark background
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#ffffff', // White x-axis labels
                },
            },
            y: {
                ticks: {
                    color: '#ffffff', // White y-axis labels
                },
            },
        },
    };

    return (
        <div className="w-full h-96">
            <Bar data={chartData} options={chartOptions} />
        </div>
    );
};

export default TrackChart;
