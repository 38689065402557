import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CreatePlaylistLayout from './CreatePlaylistLayout';
import ErrorModal from './ErrorModal'; // Import the ErrorModal

const CreatePlaylist = () => {
    const [topTracks, setTopTracks] = useState([]);
    const [playlistName, setPlaylistName] = useState('');
    const [isCustomName, setIsCustomName] = useState(false);
    const [userId, setUserId] = useState('');
    const [isCreating, setIsCreating] = useState(false);
    const [numberOfTracks, setNumberOfTracks] = useState(10);
    const [timeRange, setTimeRange] = useState('medium_term');
    const [error, setError] = useState(null); // State for error messages

    useEffect(() => {
        const token = localStorage.getItem('spotifyToken');
        if (token) {
            // Fetch user ID
            axios
                .get('https://api.spotify.com/v1/me', {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((res) => setUserId(res.data.id))
                .catch((err) => setError('Failed to fetch user ID. Please try again.'));

            // Fetch top tracks
            axios
                .get('https://api.spotify.com/v1/me/top/tracks', {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { limit: 50, time_range: timeRange },
                })
                .then((res) => setTopTracks(res.data.items))
                .catch((err) => setError('Failed to fetch top tracks. Please try again.'));
        }
    }, [timeRange]);

    const handleRemoveTrack = (trackId) => {
        setTopTracks((prevTracks) => prevTracks.filter((track) => track.id !== trackId));
    };

    const handleCreatePlaylist = async () => {
        if (!playlistName) {
            setError('Please enter a playlist name.');
            return;
        }

        const token = localStorage.getItem('spotifyToken');
        setIsCreating(true);

        try {
            const createRes = await axios.post(
                `https://api.spotify.com/v1/users/${userId}/playlists`,
                { name: playlistName, description: 'Created with Spotify Visualizer', public: false },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            const playlistId = createRes.data.id;

            const trackUris = topTracks.slice(0, numberOfTracks).map((track) => track.uri);
            await axios.post(
                `https://api.spotify.com/v1/playlists/${playlistId}/tracks`,
                { uris: trackUris },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            alert('Playlist created successfully!');
        } catch (err) {
            setError('Failed to create playlist. Please try again.');
        } finally {
            setIsCreating(false);
        }
    };

    return (
        <>
            <ErrorModal message={error} onClose={() => setError(null)} /> {/* Render the ErrorModal */}
            <CreatePlaylistLayout
                timeRange={timeRange}
                setTimeRange={setTimeRange}
                playlistName={playlistName}
                setPlaylistName={setPlaylistName}
                isCustomName={isCustomName}
                setIsCustomName={setIsCustomName}
                topTracks={topTracks}
                numberOfTracks={numberOfTracks}
                setNumberOfTracks={setNumberOfTracks}
                isCreating={isCreating}
                handleCreatePlaylist={handleCreatePlaylist}
                handleRemoveTrack={handleRemoveTrack}
            />
        </>
    );
};

export default CreatePlaylist;
