import React from 'react';
import { motion } from 'framer-motion';
import TrackChart from './Visualizations/TrackChart'; // Import the chart component

const TopTracks = ({ tracks }) => {
    return (
        <motion.div
            className="space-y-8"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
        >
            <h2 className="text-4xl font-bold text-center">Your Top Songs</h2>

            {/* Song Grid */}
            <div className="dashboard-grid">
                {tracks.map((track) => (
                    <motion.div
                        key={track.id}
                        className="card flex items-center"
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}
                    >
                        <img
                            src={track.album.images?.[0]?.url || '/default-track.png'}
                            alt={track.name}
                            className="w-16 h-16 rounded-lg mr-4"
                        />
                        <div>
                            <h3>{track.name}</h3>
                            <p>By {track.artists.map((artist) => artist.name).join(', ')}</p>
                            <p>Album: {track.album.name}</p>
                        </div>
                    </motion.div>
                ))}
            </div>

            {/* Track Chart */}
            {tracks.length > 0 && (
                <div className="space-y-8">
                    <h2 className="text-4xl font-bold text-center">Top Tracks Popularity Chart</h2>
                    <TrackChart tracks={tracks} />
                </div>
            )}
        </motion.div>
    );
};

export default TopTracks;
