import React from 'react';
import TimeRangeSelector from './TimeRangeSelector';
import PlaylistNameInput from './PlaylistNameInput';
import TrackSlider from './TrackSlider';
import TrackList from './TrackList';
import CreatePlaylistButton from './CreatePlaylistButton';

const CreatePlaylistLayout = ({
    timeRange,
    setTimeRange,
    playlistName,
    setPlaylistName,
    topTracks,
    numberOfTracks,
    setNumberOfTracks,
    isCreating,
    handleCreatePlaylist,
    handleRemoveTrack,
}) => (
    <div className="bg-gray-900 text-white p-8 rounded-lg">
        <h2 className="text-3xl font-bold mb-6">Create a Playlist</h2>

        {/* Time Range Selector */}
        <TimeRangeSelector timeRange={timeRange} setTimeRange={setTimeRange} />

        {/* Playlist Name Input */}
        <PlaylistNameInput
            playlistName={playlistName}
            setPlaylistName={setPlaylistName}
            numberOfTracks={numberOfTracks} // Pass number of tracks
        />

        {/* Track Slider */}
        <TrackSlider
            numberOfTracks={numberOfTracks}
            maxTracks={topTracks.length}
            setNumberOfTracks={setNumberOfTracks}
        />

        {/* Create Playlist Button */}
        <CreatePlaylistButton isCreating={isCreating} handleCreatePlaylist={handleCreatePlaylist} />

        {/* Track List */}
        <h3 className="text-2xl font-semibold mt-8">Your Top {numberOfTracks} Songs</h3>
        <TrackList
            tracks={topTracks}
            numberOfTracks={numberOfTracks}
            handleRemoveTrack={handleRemoveTrack}
        />
    </div>
);

export default CreatePlaylistLayout;
