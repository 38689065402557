import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Callback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const hash = window.location.hash; // Get the URL hash
        const token = new URLSearchParams(hash.substring(1)).get('access_token'); // Extract access_token

        if (token) {
            localStorage.setItem('spotifyToken', token); // Store token in localStorage
            navigate('/dashboard'); // Redirect to the dashboard
        } else {
            console.error('Access token missing in callback URL.');
            alert('Login failed. Please try again.'); // Optional user feedback
            navigate('/'); // Redirect back to login if token is missing
        }
    }, [navigate]);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white">
            {/* Spinner */}
            <div className="w-16 h-16 border-4 border-gray-300 border-t-green-500 rounded-full animate-spin"></div>
            <h1 className="text-2xl font-semibold mt-6">Processing login...</h1>
        </div>
    );
};

export default Callback;
