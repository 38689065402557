import React from 'react';

const CreatePlaylistButton = ({ isCreating, handleCreatePlaylist }) => (
    <button
        className={`w-full p-3 rounded font-semibold ${
            isCreating ? 'bg-gray-600' : 'bg-green-500 hover:bg-green-600'
        }`}
        onClick={handleCreatePlaylist}
        disabled={isCreating}
    >
        {isCreating ? 'Creating Playlist...' : 'Create Playlist'}
    </button>
);

export default CreatePlaylistButton;
