import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ListeningTrends = () => {
    const [listeningData, setListeningData] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('spotifyToken');
        if (token) {
            fetch('https://api.spotify.com/v1/me/player/recently-played?limit=50', {
                headers: { Authorization: `Bearer ${token}` },
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data && data.items) {
                        setListeningData(data.items);
                    }
                })
                .catch((err) => console.error('Error fetching listening trends:', err));
        }
    }, []);

    // Prepare data for the line chart
    const chartData = {
        labels: listeningData.map((item) => new Date(item.played_at).toLocaleTimeString()), // X-axis: Play times
        datasets: [
            {
                label: 'Track Popularity',
                data: listeningData.map((item) => item.track.popularity), // Y-axis: Track popularity
                borderColor: '#1db954', // Spotify green
                backgroundColor: 'rgba(29, 185, 84, 0.3)', // Semi-transparent green
                borderWidth: 2,
                tension: 0.4, // Smooth line
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: '#ffffff', // White labels for dark background
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#ffffff', // X-axis label color
                },
            },
            y: {
                ticks: {
                    color: '#ffffff', // Y-axis label color
                },
            },
        },
    };

    return (
        <div className="space-y-8">
            <h2 className="text-4xl font-bold text-center">Your Listening Trends</h2>
            <div className="w-full h-96">
                {listeningData.length > 0 ? (
                    <Line data={chartData} options={chartOptions} />
                ) : (
                    <p className="text-center">Loading listening trends...</p>
                )}
            </div>
        </div>
    );
};

export default ListeningTrends;
