import axios from 'axios';

const SPOTIFY_API_BASE_URL = 'https://api.spotify.com/v1';

/**
 * Fetch Spotify User Profile
 * @param {string} token - Spotify API token
 * @returns {Promise<Object>} - User profile data
 */
const fetchUserProfile = async (token) => {
    const response = await axios.get(`${SPOTIFY_API_BASE_URL}/me`, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
};

/**
 * Fetch Spotify User's Top Tracks
 * @param {string} token - Spotify API token
 * @param {number} limit - Number of tracks to fetch
 * @param {string} timeRange - Time range for top tracks
 * @returns {Promise<Array>} - List of top tracks
 */
const fetchTopTracks = async (token, limit = 20, timeRange = 'medium_term') => {
    const response = await axios.get(`${SPOTIFY_API_BASE_URL}/me/top/tracks`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { limit, time_range: timeRange },
    });
    return response.data.items;
};

/**
 * Fetch Spotify User's Top Artists
 * @param {string} token - Spotify API token
 * @param {number} limit - Number of artists to fetch
 * @param {string} timeRange - Time range for top artists
 * @returns {Promise<Array>} - List of top artists
 */
const fetchTopArtists = async (token, limit = 50, timeRange = 'medium_term') => {
    const response = await axios.get(`${SPOTIFY_API_BASE_URL}/me/top/artists`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { limit, time_range: timeRange },
    });
    return response.data.items;
};

/**
 * Filter Tracks by Release Year
 * @param {Array} tracks - List of tracks
 * @param {string} year - Desired release year (e.g., '2022')
 * @returns {Array} - Filtered list of tracks
 */
const filterTracksByYear = (tracks, year) => {
    return tracks.filter((track) => {
        const releaseDate = track.album.release_date; // Format: YYYY-MM-DD
        return releaseDate.startsWith(year);
    });
};

/**
 * Fetch All Required Spotify Data
 * @param {string} token - Spotify API token
 * @param {string} timeRange - Time range for top tracks and artists
 * @returns {Promise<Object>} - Combined data object
 */
export const fetchSpotifyData = async (token, timeRange = 'medium_term') => {
    try {
        const [userProfile, topTracks, topArtists] = await Promise.all([
            fetchUserProfile(token),
            fetchTopTracks(token, 50, timeRange),
            fetchTopArtists(token, 50, timeRange),
        ]);

        return {
            userProfile,
            topTracks,
            topArtists,
        };
    } catch (error) {
        console.error('Error fetching Spotify data:', error);
        throw error;
    }
};

/**
 * Fetch and Filter Tracks by Year
 * @param {string} token - Spotify API token
 * @param {string} year - Desired release year (optional)
 * @param {string} timeRange - Time range for top tracks
 * @returns {Promise<Array>} - Filtered list of tracks
 */
export const fetchFilteredTracks = async (token, year = '', timeRange = 'long_term') => {
    const tracks = await fetchTopTracks(token, 50, timeRange);

    if (year) {
        return filterTracksByYear(tracks, year);
    }

    return tracks; // Return unfiltered tracks if no year is specified
};
