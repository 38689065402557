import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import axios from 'axios';

// Register the required elements
ChartJS.register(ArcElement, Tooltip, Legend, Title);

const GenrePieChart = () => {
    const [genreData, setGenreData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('spotifyToken');

        const fetchTopArtists = async () => {
            try {
                const response = await axios.get('https://api.spotify.com/v1/me/top/artists', {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { limit: 50, time_range: 'medium_term' },
                });

                const genres = response.data.items.flatMap((artist) => artist.genres);

                // Calculate genre counts
                const genreCounts = genres.reduce((acc, genre) => {
                    acc[genre] = (acc[genre] || 0) + 1;
                    return acc;
                }, {});

                // Prepare data for Pie Chart
                const labels = Object.keys(genreCounts);
                const data = Object.values(genreCounts);

                // Calculate percentages
                const total = data.reduce((sum, count) => sum + count, 0);
                const percentages = labels.map((label, index) => ({
                    genre: label,
                    percentage: ((data[index] / total) * 100).toFixed(2),
                    count: data[index],
                    color: [
                        '#FF6384',
                        '#36A2EB',
                        '#FFCE56',
                        '#4BC0C0',
                        '#9966FF',
                        '#FF9F40',
                        '#C9CBCF',
                    ][index % 7], // Match color to pie chart
                }));

                // Sort percentages for legend
                const sortedPercentages = percentages.sort((a, b) => b.percentage - a.percentage);

                setGenreData({
                    chartData: {
                        labels,
                        datasets: [
                            {
                                data,
                                backgroundColor: percentages.map((item) => item.color),
                                borderWidth: 1,
                            },
                        ],
                    },
                    percentages: sortedPercentages,
                });

                setLoading(false);
            } catch (error) {
                console.error('Error fetching top artists:', error);
                setLoading(false);
            }
        };

        if (token) fetchTopArtists();
    }, []);

    if (loading) {
        return <p className="text-white">Loading genre data...</p>;
    }

    if (!genreData || !genreData.percentages) {
        return <p className="text-red-500">Failed to load genre data.</p>;
    }

    return (
        <div className="bg-gray-900 p-6 rounded-lg text-white flex flex-wrap justify-center items-center gap-8">
            {/* Chart Section */}
            <div className="relative" style={{ width: '400px', height: '400px' }}>
                <Pie
                    data={genreData.chartData}
                    options={{
                        responsive: true,
                        plugins: {
                            title: {
                                display: true,
                                text: 'Your Top Genres Based on Listening Habits',
                                font: { size: 18, weight: 'bold' },
                                color: '#fff',
                            },
                            tooltip: {
                                callbacks: {
                                    label: (tooltipItem) => {
                                        const index = tooltipItem.dataIndex;
                                        const count = genreData.percentages[index].count;
                                        const percentage = genreData.percentages[index].percentage;
                                        return `${tooltipItem.label}: ${count} songs (${percentage}%)`;
                                    },
                                },
                            },
                            legend: { display: false },
                        },
                        maintainAspectRatio: false,
                    }}
                />
            </div>

            {/* Legend Section */}
            <div className="flex-1">
                <h2 className="text-2xl font-bold mb-4">Top 10 Genres</h2>
                <ul className="space-y-2">
                    {genreData.percentages.slice(0, 10).map((item, index) => (
                        <li key={index} className="flex items-center space-x-3">
                            <span className="text-xl font-bold">{index + 1}.</span>
                            <span
                                className="block w-4 h-4 rounded-full"
                                style={{ backgroundColor: item.color }}
                            ></span>
                            <span className="flex-1">{item.genre}</span>
                            <span>{item.percentage}%</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default GenrePieChart;
