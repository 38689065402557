import React from 'react';
import { SPOTIFY_AUTH_ENDPOINT, CLIENT_ID, REDIRECT_URI, SCOPES } from '../config/spotifyConfig';

const LoginPage = () => {
    // Build the Spotify login URL
    const AUTH_URL = `${SPOTIFY_AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${SCOPES.join(
        '%20'
    )}&response_type=token&show_dialog=true`;

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-green-500 to-green-700 text-white text-center">
            <h1 className="text-5xl font-extrabold mb-6 drop-shadow-lg">
                Spotify Visualizer
            </h1>
            <p className="text-lg max-w-md mb-8">
                Discover your top tracks, artists, and listening habits with a beautifully designed Spotify experience!
            </p>
            <a
                href={AUTH_URL}
                className="bg-white text-green-500 font-bold text-lg py-3 px-6 rounded-full shadow-md hover:shadow-lg hover:bg-gray-100 transition duration-300"
            >
                Log in with Spotify
            </a>
        </div>
    );
};

export default LoginPage;
